<template>
    <notifications group="flash-notification" :position="position"></notifications>
</template>

<script>
    export default {
        name: 'FlashNotification',
        props: {
            type: {
                type: true,
                required: false,
            },
            message: {
                type: String,
                required: true
            },
            titre: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: false,
                default: 'top right'
            },
        },
        data() {
            return {
                notification: this.message,
                alertClass: this.type,
                title: this.titre,
            }
        },

        mounted () {
            if (this.notification) {
                this.$notify({
                    group: 'flash-notification',
                    title: this.title,
                    text: this.notification,
                    type: this.alertClass
                })
            }
        },
    }
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("notifications", {
    attrs: { group: "flash-notification", position: _vm.position },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
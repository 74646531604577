/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('../bootstrap');
require('../plugins');

window.$ = window.jQuery = require('jquery');

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification';
import moment from 'moment';

import CommentWrapper from './vue/components/Comment/Wrapper';
import FlashNotification from './vue/components/Common/FlashNotification';


Vue.use(VueAxios, axios);
Vue.use(Notifications);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./vue/components/ExampleComponent.vue').default);
Vue.component('CommentWrapper', CommentWrapper);
Vue.component('FlashNotification', FlashNotification);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.filter('formatDateFR', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

const app = new Vue({
    el: '#wrapper',
    mounted() {
        console.log('vue front loaded')
    },
});

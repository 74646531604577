<template>
  <div>
      <hr>
      <h4 class="mt-3">Commentaires</h4>
      <div class="row" v-for="com in comments">
        <p class="font-weight-bold">{{ com.date | formatDateFR}} - {{ com.nom}}</p>
        <p>{{ com.description}}</p>
      </div>
      <p v-if="comments.length == 0">Aucun commentaire pour le moment</p>
      <h4 class="mt-3">Laissez un commentaire</h4>
      <div class="row">
        <div class="col-sm-8 offset-sm-2">
          <input type="text" class="form form-control" placeholder="Votre Nom" v-model="nom" required>
          <textarea class="form form-control mt-2" rows="5" placeholder="Votre message" v-model="commentaire" required></textarea>
          <div class="text-center mt-3">
            <button class="btn btn-success text-center" v-on:click="save">Commenter</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "CommentWrapper",
  props: {
    article: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      required: false
    }
  },
  methods: {
    async save() {

      if(this.commentaire && this.nom) {
        this.$notify({
          title: 'Commentaire enregistré',
          text: 'Il sera publié dans quelques minutes',
          type: 'success'
        })

        let datas = await axios.post('/blog-article/saveComment', {
          nom: this.nom,
          commentaire: this.commentaire,
          article_id: this.article.id
        })

        this.commentaire = null
        this.nom = null
      }
    }
  },
  data() {
    return {
      nom: null,
      commentaire: null
    }
  }
}
</script>

<style scoped>

</style>